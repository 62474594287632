export class FixedTel {
  private $fixedTel: JQuery;
  private $fixedTelRemove: JQuery;

  constructor() {
    this.$fixedTel = $(".js-fixed-tel");
    this.$fixedTelRemove = $(".js-fixed-tel-remove");
  }

  public init(): void {
    this.$fixedTelRemove.on("click", () => {
      this.$fixedTel.fadeOut();
    });
  }
}
